export default [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "客户",
    dataIndex: "client_name",
    fixed: "left",
    width: 120,
    ellipsis: true,
  },
  {
    title: "入库配送费用(元)",
    dataIndex: "stock_in_transport_amount",
    width: 130,
    ellipsis: true,
  },
  {
    title: "入库卸货费用(元)",
    dataIndex: "stock_in_handling_amount",
    width: 130,
    ellipsis: true,
  },
  {
    title: "入库其他费用(元)",
    dataIndex: "stock_in_expense_amount",
    width: 130,
    ellipsis: true,
  },
  {
    title: "出库配送费用(元)",
    dataIndex: "stock_out_transport_amount",
    width: 130,
    ellipsis: true,
  },
  {
    title: "出库装车费用(元)",
    dataIndex: "stock_out_handling_amount",
    width: 130,
    ellipsis: true,
  },
  {
    title: "出库其他费用(元)",
    dataIndex: "stock_out_expense_amount",
    width: 130,
    ellipsis: true,
  },
  {
    title: "仓储费用(元)",
    dataIndex: "storage_charge_amount",
    width: 100,
    ellipsis: true,
  },
  {
    title: "总金额(元)",
    dataIndex: "total_amount",
    width: 100,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    fixed: "right",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];
